import CryptoJS from "crypto-js";
import axios from "axios";
import i18n from "i18next";
import { message as antdMessage } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";

import { QueryRequest } from "@services/apollo/api_service";
import { historyRef } from "@views/routes/Router";
import { fileQuery } from "./constants";

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const encryptData = (data, salt) => CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (cipher_text, salt) => {
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export function storeItem(key, data) {
  const salt = process.env.REACT_APP_SALT || "";
  let value = JSON.stringify(data);
  let encryptedKey = window.btoa(key);
  let encryptedValue = encryptData(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

export function retrieveItem(key) {
  const salt = process.env.REACT_APP_SALT || "";
  try {
    let encryptedKey = window.btoa(key);
    let value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decryptData(value, salt);
    return JSON.parse(decryptedValue);
  } catch (e) {
    return null;
  }
}

export function clearLocalStorage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}

export async function fileUpload(file, storageName) {
  let url = await getURL(storageName, file?.name);

  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }

  async function getURL(type, fileName) {
    let { data } = await QueryRequest(fileQuery, { type, fileName });
    return data.getUploadUrl.url;
  }
}

export function useIsRTL() {
  const localeRTLList = ["ar", "he"];
  let locale = i18n.language || "en";
  if (locale && localeRTLList.includes(locale)) {
    return { isRTL: true, alignLeft: "right", alignRight: "left" };
  }
  return { isRTL: false, alignLeft: "left", alignRight: "right" };
}

export const navigate = (path) => {
  historyRef.current.push(path);
};

export const navigateWithProps = (path, props) => {
  historyRef.current.push({
    pathname: path,
    props_state: props,
  });
};

export const navigateBack = () => {
  historyRef.current.goBack();
};

export function showNotification({ type, message, duration = 3 }) {
  switch (type) {
    case "success":
      antdMessage.success(message, duration);
      break;
    case "warn":
      antdMessage.warning(message, duration);
      break;
    case "error":
      antdMessage.error(message, duration);
      break;
    default:
      antdMessage.info(message, duration);
  }
}

export function showToast({ type, message, duration = 5000, id = uuidv4() }) {
  if (id && toast.isActive(id)) {
    return;
  }

  switch (type) {
    case "info":
      toast.info(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "success":
      toast.success(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "warn":
      toast.warn(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "error":
      toast.error(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    default:
      toast(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
  }
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function getProfileUrl(url, member_gender) {
  if (url && member_gender) {
    return url;
  } else if (member_gender === "f" && !url) {
    return "https://blr1.vultrobjects.com/docy-assets/female-avatar.png";
  } else if (member_gender === "m" && !url) {
    return "https://blr1.vultrobjects.com/docy-assets/male-avatar.png";
  }
}
